import React from "react";
import { Item } from "../../model/Item";
import SpecificItemComponent from "./SpecificItemComponent";

interface ItemListProps {
  items: Item[];
}

const ItemListComponent: React.FC<ItemListProps> = ({ items }) => {
  const sortedItems = [...items].sort((a, b) => {
    const priceA = a.discountPrice ?? a.normalPrice ?? Number.MAX_SAFE_INTEGER;
    const priceB = b.discountPrice ?? b.normalPrice ?? Number.MAX_SAFE_INTEGER;

    return priceA - priceB;
  });
  return (
    <div className="space-y-2">
      {sortedItems.map((item) => (
        <SpecificItemComponent key={item.id} item={item} />
      ))}
    </div>
  );
};

export default ItemListComponent;
