import { ConfirmComponent } from "../components/ConfirmComponent";
import { AvailableStoresList } from "../constant/availableStores";
import { useShoppingList } from "../hooks/useShoppingList";
import { ItemGroup } from "../model/ItemGroup";
import { ItemGroupWithDesiredWeight } from "../model/ItemGroupWithDesiredWeight";
import { toast } from "react-toastify";
import { createItemGroupWithDesiredWeight } from "../services/apiService";

export function useAddItemToShoppinglist() {
  const { shoppingList, addItem, addToExistingShoppingListItem } =
    useShoppingList();

  async function findAndAdd(itemGroup: ItemGroup, weightForItemGroup: number) {
    const itemGroupWithDesiredWeight: ItemGroupWithDesiredWeight =
      await createItemGroupWithDesiredWeight(
        weightForItemGroup,
        itemGroup.RecommendedItem.unitsOfMeasure,
        itemGroup
      );

    if (itemGroupWithDesiredWeight.cheapestItemsForEachStore.length === 0) {
      return;
    }

    itemGroupWithDesiredWeight.cheapestItemsForEachStore.forEach(
      (CheapestItemCombinationForStore, index) => {
        if (CheapestItemCombinationForStore.itemsSelected === null) {
          toast.warning(
            `${itemGroup.name} is not available in ${AvailableStoresList[index]}`
          );
          return;
        }
      }
    );

    addItem(itemGroupWithDesiredWeight);
  }

  return function addItemToShoppinglist(
    itemGroup: ItemGroup,
    desiredWeight: number
  ) {
    let weightForItemGroup = desiredWeight;

    if (shoppingList.some((item) => item.itemGroup.name === itemGroup.name)) {
      ConfirmComponent(
        "Bekræft",
        `${itemGroup.name} er allerede i din indkøbsliste. Vil du opdatere vægten?`,
        "Ja",
        "Nej",
        () => {
          addToExistingShoppingListItem(itemGroup, weightForItemGroup);
        },
        () => {
          return;
        }
      );
    } else {
      findAndAdd(itemGroup, weightForItemGroup);
    }
  };
}
