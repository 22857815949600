import { createUserWithEmailAndPassword } from "firebase/auth";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { SwiftCartLogo } from "../../components/SwiftCartLogoComponent";
import useAuth from "../../hooks/useAuth";
import { auth } from "../../utils/firebaseSetup";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { loginSchema } from "../../utils/validationSchema";

export function RegisterPage() {
  const navigate = useNavigate();
  const user = useAuth();

  const {
    register,
    handleSubmit,
    formState: { errors },
    trigger,
  } = useForm({
    resolver: yupResolver(loginSchema),
    mode: "onChange",
  });

  // Redirect logged-in users away from the registration page
  useEffect(() => {
    if (user) {
      navigate("/shoppingList");
    }
  }, [user]);

  const registerUser = (data) => {
    const { email, password } = data;
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        console.log("Success register: ", user);
        user.getIdToken().then(() => {
          console.log("acquiring token");
          console.log("navigating to new page");
        });
        navigate("/shoppingList");
      })
      .catch((error) => {
        console.error("Error during registration:", error);
      });
  };

  return (
    <div className="flex flex-col w-screen h-screen bg-secondary overflow-hidden">
      <SwiftCartLogo />
      <div className="flex justify-center items-center ml-10">
        <form
          className="flex flex-col shadow-action shadow-even bg-darkBackground py-16 px-28 rounded-lg"
          onSubmit={handleSubmit(registerUser)}
        >
          <h1 className="flex text-lightText items-center text-4xl mb-10">
            Opret konto
          </h1>
          <input
            className="shadow-action shadow-even text-lightText bg-darkBackground px-5 py-4 mt-5 rounded-lg"
            id="usernameInput"
            type="email"
            placeholder="Email"
            {...register("email")}
            onKeyUp={() => trigger("email")}
          />
          {errors.email && (
            <p style={{ color: "red" }}>{errors.email.message}</p>
          )}

          <input
            className="shadow-action shadow-even text-lightText bg-darkBackground px-5 py-4 my-10 rounded-lg"
            id="passwordInput"
            type="password"
            placeholder="Password"
            {...register("password")}
            onKeyUp={() => trigger("password")}
          />
          {errors.password && (
            <p style={{ color: "red" }}>{errors.password.message}</p>
          )}

          <button
            className="shadow-secondaryHover shadow-even text-lightText bg-action hover:bg-actionHover px-4 py-3 mb-5 rounded-lg"
            type="submit"
          >
            Registrer
          </button>
        </form>
      </div>
    </div>
  );
}
