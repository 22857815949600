export const RecipeCategoryButtonComponent = ({
  children,
  onClick,
  category,
  isSelected,
}) => {
  return (
    <button
      className={`font-bold rounded-full px-3 py-2 text-darkText ${
        isSelected ? "bg-action " : "bg-secondary"
      }`}
      onClick={() => onClick(category)}
    >
      {children}
    </button>
  );
};
