import { signInWithEmailAndPassword } from "firebase/auth";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SwiftCartLogo } from "../../components/SwiftCartLogoComponent";
import useAuth from "../../hooks/useAuth";
import { auth } from "../../utils/firebaseSetup";
import { toast } from "react-toastify";

export function LoginPage() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const user = useAuth();

  // if user exist, they can not redirect to login page by changing URL path
  useEffect(() => {
    if (user) {
      navigate("/shoppingList");
    }
  }, [user]);

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  function loginUser() {
    signInWithEmailAndPassword(auth, username, password)
      .then(() => {
        console.log("Login successful");
        navigate("/shoppingList");
        toast.success("Du er nu logget ind");
      })
      .catch((error) => {
        switch (error.code) {
          case "auth/invalid-email":
            toast.error("Ugyldig email adresse");
            break;
          case "auth/user-disabled":
            toast.error("Denne konto er deaktiveret");
            break;
          case "auth/user-not-found":
            toast.error("Ingen bruger fundet med denne email");
            break;
          case "auth/wrong-password":
            toast.error("Forkert adgangskode");
            break;
          default:
            toast.error("Der opstod en fejl under login");
        }
      });
  }

  return (
    <div className="flex flex-col w-screen h-screen bg-secondary overflow-hidden">
      <SwiftCartLogo />
      <div className="flex justify-center items-center ml-10">
        <div className="flex flex-col shadow-action shadow-even bg-darkBackground py-16 px-28 rounded-lg">
          <h1 className="flex text-lightText items-center text-4xl mb-10">
            Log ind
          </h1>
          <input
            className="shadow-action shadow-even text-lightText bg-darkBackground px-5 py-4 mt-5 rounded-lg"
            id="usernameInput"
            onChange={handleUsernameChange}
            type="email"
            value={username}
            placeholder="Email"
            required
          ></input>
          <input
            className="shadow-action shadow-even text-lightText bg-darkBackground px-5 py-4 my-10 rounded-lg"
            id="passwordInput"
            onChange={handlePasswordChange}
            type="password"
            value={password}
            placeholder="Password"
            required
          ></input>
          <button
            className="shadow-secondaryHover shadow-even text-lightText bg-action hover:bg-actionHover px-4 py-3 mb-5 rounded-lg"
            onClick={loginUser}
          >
            Login
          </button>
          <div className="flex flex-row space-x-1">
            <p className="text-lightText">Har du ikke en konto?</p>
            <a href="/register" className="text-blue-600 underline">
              Opret her
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
