import React, { useEffect } from "react";
import { ItemGroup } from "../../model/ItemGroup";
import ItemListComponent from "./ItemListComponent";

interface ItemGroupModalProps {
  itemGroup: ItemGroup;
  onClose: () => void;
}

const ItemGroupModal: React.FC<ItemGroupModalProps> = ({
  itemGroup,
  onClose,
}) => {
  const { RecommendedItem, name } = itemGroup;
  const imageUrl = RecommendedItem.image || "https://via.placeholder.com/500";

  // Close modal on Escape key press
  useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onClose();
      }
    };

    window.addEventListener("keydown", handleEsc);

    document.body.style.overflow = "hidden"; //prevent background scrolling

    return () => {
      window.removeEventListener("keydown", handleEsc);
      document.body.style.overflow = "auto"; // Restore scrolling
    };
  }, [onClose]);

  return (
    <div
      className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
      onClick={onClose} // Close on background click
    >
      <div
        className="relative bg-white rounded-lg shadow-lg w-full max-w-screen-xl mx-2 sm:mx-4 my-4 overflow-y-auto"
        onClick={(e) => e.stopPropagation()} // Prevent click from bubbling up
        style={{ maxHeight: "95vh" }} // Adjust max height if needed
      >
        {/* Close button */}
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-600 hover:text-gray-800 focus:outline-none"
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>

        <div className="p-6">
          {/* Two-column layout */}
          <div className="flex flex-col md:flex-row">
            {/* Left Side: Image and Name */}
            <div className="md:w-1/2 flex flex-col items-center">
              {/* Name of the item group */}
              <h2 className="text-xl md:text-4xl font-bold mb-4 text-center">
                {name}
              </h2>
              {/* Large image */}
              <div
                className="
    mb-4 w-full flex items-center justify-center bg-white-100 rounded-lg overflow-hidden
    h-48 sm:h-48 md:h-64 lg:h-[20rem] xl:h-[24rem] 2xl:h-[32rem]
    max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl 2xl:max-w-2xl
  "
              >
                <img
                  src={imageUrl}
                  alt={name}
                  className="object-contain w-full h-full"
                />
              </div>
            </div>
            {/* Right Side: Contents and Nutrition Info */}
            <div className="md:w-1/2 md:pl-6 flex flex-col">
              {/* RecommendedItem contents */}
              {RecommendedItem.content && (
                <div className="bg-gray-100 rounded-lg p-4 mb-4 flex-1">
                  <h3 className="text-lg font-semibold mb-2">Indhold</h3>
                  <p>{RecommendedItem.content}</p>
                </div>
              )}

              {/* RecommendedItem nutritionInfo */}
              {RecommendedItem.nutritionInfo && (
                <div className="bg-gray-100 rounded-lg p-4 flex-1">
                  <h3 className="text-lg font-semibold mb-2">
                    Næringsindhold per 100 g/ml
                  </h3>
                  <ul className="list-disc list-inside space-y-1">
                    <li>
                      Kalorier {RecommendedItem.nutritionInfo.calories} kcal
                    </li>
                    <li>Fedt {RecommendedItem.nutritionInfo.fat} g</li>
                    <li>
                      Heraf mættede fedtsyrer{" "}
                      {RecommendedItem.nutritionInfo.saturatedFat} g
                    </li>
                    <li>
                      Kulhydrater{" "}
                      {RecommendedItem.nutritionInfo.carbohydrates} g
                    </li>
                    <li>Sugars: {RecommendedItem.nutritionInfo.sugars} g</li>
                    <li>Fiber: {RecommendedItem.nutritionInfo.fiber} g</li>
                    <li>Protein: {RecommendedItem.nutritionInfo.protein} g</li>
                    <li>Salt: {RecommendedItem.nutritionInfo.salt} g</li>
                  </ul>
                </div>
              )}
            </div>
          </div>
          <div className="mt-6">
            <h3 className="text-lg font-semibold mb-2">Varer i denne gruppe</h3>
            <div className="bg-gray-100 rounded-lg p-4 overflow-y-auto">
              <ItemListComponent items={itemGroup.itemList} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ItemGroupModal;
