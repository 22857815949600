import { useShoppingList } from "../hooks/useShoppingList";
import { ItemGroup } from "../model/ItemGroup";
import { useAddItemToShoppinglist } from "../utils/addItemToShoppinglist";
import { maxWeight } from "../utils/validationVariables";
import { ConfirmComponent } from "./ConfirmComponent";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { addToShoppingListSchema } from "../utils/validationSchema";

export function AddNewItemWithAmountComponent({
  itemGroup,
  addItemClicked,
}: {
  itemGroup: ItemGroup;
  index: number;
  addItemClicked: () => void;
}) {
  const addItemToShoppinglist = useAddItemToShoppinglist();
  const { shoppingList, addToExistingShoppingListItem } = useShoppingList();
  const {
    register,
    formState: { errors },
    trigger,
    getValues,
    setValue,
  } = useForm({
    resolver: yupResolver(addToShoppingListSchema),
    mode: "onChange",
  });

  function addShoppingItem(itemGroup: ItemGroup) {
    let weightForItemGroup = getValues("weight");
    weightForItemGroup = Number(weightForItemGroup);

    let sameItemsInShoppinglist = shoppingList.filter(
      (item) => item.itemGroup.name === itemGroup.name
    );
    if (sameItemsInShoppinglist.length > 0) {
      if (
        sameItemsInShoppinglist[0].desiredWeight + weightForItemGroup >
        maxWeight
      ) {
        toast.warning("Du har allerede denne vare i indkøbslisten");
        toast.warning(`Vægten kan ikke være mere end ${maxWeight} tilsammen`);
        return;
      }
      ConfirmComponent(
        "Bekræft",
        `${itemGroup.name} er allerede i din indkøbsliste. Vil du opdatere vægten?`,
        "Ja",
        "Nej",
        () => {
          addToExistingShoppingListItem(itemGroup, weightForItemGroup);
          addItemClicked();
        },
        () => {
          return;
        }
      );
      return;
    }
    addItemToShoppinglist(itemGroup, weightForItemGroup);
  }

  return (
    <div className="flex flex-row justify-center items-center h-14">
      <div className="flex flex-col w-5/12 ml-1">
        <input
          placeholder="Mængde"
          type="number"
          className={`w-full p-2 border rounded-lg text-base focus:outline-none focus:ring-2 focus:ring-action 
            ${errors.weight ? "focus:ring-cancel" : ""}`}
          {...register("weight")}
          onKeyUp={() => trigger("weight")}
        />
        {errors.weight && (
          <p className="text-red-500 text-xs ml-2">{errors.weight.message}</p>
        )}
      </div>

      <p className="flex min-w-fit mx-2 py-1 px-3 border rounded-lg text-lg bg-lightgreyBackground">
        {itemGroup.RecommendedItem.unitsOfMeasure}
      </p>
      <button
        className="flex justify-center items-center bg-action w-10 h-10 rounded-lg ml-2 text-3xl font-bold hover:bg-actionHover"
        onClick={() => {
          trigger("weight");
          if (!errors.weight) {
            addShoppingItem(itemGroup);
            setValue("weight", undefined);
            addItemClicked();
          }
        }}
      >
        <img className="w-8 h-8" src="/icon/add_sign.png" alt="" />
      </button>
    </div>
  );
}
