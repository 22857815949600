import React from "react";
import { Item } from "../../model/Item";

interface SpecificItemProps {
  item: Item;
}

const SpecificItemComponent: React.FC<SpecificItemProps> = ({ item }) => {
  const {
    image,
    name,
    brand,
    discountPrice,
    pricePerKg,
    pricePerUnit,
    units,
    unitsOfMeasure,
    storeName,
  } = item;

  const imageUrl = image || "https://via.placeholder.com/100";
  const price = discountPrice || 0;
  const displayUnitDict = {
    g: "Kg",
    kg: "Kg",
    l: "L",
    ml: "L",
    cl: "L",
    stk: "Stk",
  };
  const displayUnit = displayUnitDict[unitsOfMeasure] ?? "";

  return (
    <div className="flex items-center bg-white rounded-lg p-2 shadow-sm">
      {/* Item image */}
      <div className="w-12 h-12 flex-shrink-0">
        <img
          src={imageUrl}
          alt={name}
          className="w-full h-full object-contain rounded-md"
        />
      </div>

      {/* Item details */}
      <div className="ml-3 flex-grow">
        <h4 className="text-sm font-semibold truncate">{name}</h4>
        <p className="text-xs text-gray-600">{brand}</p>
        <p className="text-xs text-gray-600">
          {units} {unitsOfMeasure}
        </p>
        <p className="text-xs text-gray-600">{storeName}</p>
      </div>

      {/* Price */}
      <div className="ml-3 text-right">
        <p className="text-sm font-semibold text-black">
          {(price / 100).toFixed(2)}
        </p>
        <p className="text-sm text-gray-500">
          {(pricePerKg / 100).toFixed(2) ?? pricePerUnit}/{displayUnit}
        </p>
      </div>
    </div>
  );
};

export default SpecificItemComponent;
