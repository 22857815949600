import { Item } from "../model/Item";
import { ItemWithCount } from "../model/ItemWithCount";

export function filterSelectedItemsToHaveCount(
  itemsSelected: Item[] | null | undefined
): ItemWithCount[] {
  // Return empty array if input is null/undefined
  if (!itemsSelected) {
    return [];
  }

  const filteredItems = itemsSelected.reduce(
    (acc, chosenItem, index, itemsArray) => {
      // Skip if item is null/undefined
      if (!chosenItem) {
        return acc;
      }

      // Count occurrences of each item
      if (!acc.itemCounts) {
        acc.itemCounts = itemsArray.reduce((counts, item) => {
          counts[item.id] = (counts[item.id] || 0) + 1;
          return counts;
        }, {});
      }

      // Check if the current item is the last occurrence
      const isLastOccurrence =
        index === itemsArray.length - 1 ||
        chosenItem.id !== itemsArray[index + 1]?.id;

      if (isLastOccurrence) {
        // Push the chosenItem along with its count into the accumulator
        acc.filteredItems.push({
          ...chosenItem,
          count: acc.itemCounts[chosenItem.id],
        });
      }

      return acc;
    },
    { itemCounts: null, filteredItems: [] }
  ).filteredItems;
  return filteredItems;
}
