import { useEffect, useState } from "react";
import { AvailableStoresList } from "../constant/availableStores";
import { useShoppingList } from "../hooks/useShoppingList";

const selectedColor = "bg-action";
const defaultColor = "bg-secondary";
const ColorHover = "bg-secondaryHover";

const selectedColorWarning = "bg-warning";
const defaultColorWarning = "bg-warningSecondary";
const ColorHoverWarning = "bg-warningHover";

export function StorePriceComponent({
  store,
  clickable,
  onSelectStore,
  isSelected,
  totalPrice,
}: {
  store: string;
  clickable: boolean;
  onSelectStore?: (storeName: string) => void;
  isSelected?: boolean;
  totalPrice: number;
}) {
  const [buttonColor, setButtonColor] = useState(defaultColor);
  const { shoppingList } = useShoppingList();
  //buttonColor = isSelected ? selectedColor : defaultColor; // Set color based on selected state
  const chosenStoreIndex = AvailableStoresList.findIndex((availableStores) => {
    return availableStores === store;
  });

  // Check if there is a missing item in the store
  const hasMissingItems = shoppingList.some(
    (item) =>
      item.cheapestItemsForEachStore[chosenStoreIndex].itemsSelected === null ||
      item.cheapestItemsForEachStore[chosenStoreIndex].itemsSelected ===
        undefined
  );

  useEffect(() => {
    // Update button color based on store selected and any missing items
    if (isSelected) {
      setButtonColor(hasMissingItems ? selectedColorWarning : selectedColor);
    } else {
      setButtonColor(hasMissingItems ? defaultColorWarning : defaultColor);
    }
  }, [isSelected, shoppingList, chosenStoreIndex]);

  // Change hover color based on store selected and any missing items
  const hoverColor = hasMissingItems ? ColorHoverWarning : ColorHover;

  return (
    <div
      className={`flex ${buttonColor} items-center space-x-2 p-2 m-1 rounded-full w-fit h-16 shadow-lg ${
        clickable ? `cursor-pointer hover:${hoverColor}` : ""
      }`}
      onClick={() => onSelectStore(store)}
      style={{
        pointerEvents: clickable ? "auto" : "none",
      }}
    >
      <img
        className="rounded-full w-fit h-full"
        src={`/img/${store}.png`}
        alt="logo"
      />
      <p className="font-bold text-2xl w-fit p-2">
        {(totalPrice ? totalPrice / 100 : 0).toFixed(2)} kr
      </p>
    </div>
  );
}
