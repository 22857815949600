import "react-confirm-alert/src/react-confirm-alert.css";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";
import { NavBarComponent } from "./components/NavbarComponent";
import { ShoppingListProvider } from "./context/ShoppingListProvider";
import useAuth from "./hooks/useAuth";
import ItemGroupsPage from "./pages/item/ItemGroupsPage";
import { LoginPage } from "./pages/login/LoginPage";
import { RecipeDetailsPage } from "./pages/recipe/RecipeDetailsPage";
import { RecipePage } from "./pages/recipe/RecipePage";
import { RegisterPage } from "./pages/register/RegisterPage";
import { ShoppingListPage } from "./pages/shoppingList/ShoppingListPage";
import { Unauthorized } from "./pages/unauthorized/UnauthorizedPage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const user = useAuth();

  return (
    <ShoppingListProvider>
      <Router>
        <div className="flex flex-col h-full">
          {/* Conditional rendering of navbar based on user or not */}
          {user && <NavBarComponent></NavBarComponent>}
          <Routes>
            <Route path="/" element={<LoginPage />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route
              path="/shoppingList"
              element={user ? <ShoppingListPage /> : <Unauthorized />}
            />
            <Route
              path="/recipe"
              element={user ? <RecipePage /> : <Unauthorized />}
            />
            <Route
              path="/recipeDetails/:recipeId"
              element={user ? <RecipeDetailsPage /> : <Unauthorized />}
            ></Route>
            <Route
              path="/items"
              element={user ? <ItemGroupsPage /> : <Unauthorized />}
            />
          </Routes>
          <ToastContainer
            position="bottom-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            pauseOnHover
          />
        </div>
      </Router>
    </ShoppingListProvider>
  );
}

export default App;
