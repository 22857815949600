interface SwiftCartLogoProps {
  hasShadow?: boolean; // Define the type for the prop
}

export function SwiftCartLogo({ hasShadow = true }: SwiftCartLogoProps) {
  return (
    <div className="flex flex-row items-center ml-10 ">
      <h1
        className={`flex text-4xl h-fit font-bold font-garret text-logoText ${
          hasShadow ? "text-shadow-lg shadow-logoShadow" : ""
        }`}
      >
        SWIFTCART
      </h1>
      <img
        className="flex size-20"
        src="/icon/swiftcart_logo.png"
        alt="swiftcart logo"
      ></img>
    </div>
  );
}
