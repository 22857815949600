import React from "react";
import { ItemGroup } from "../../model/ItemGroup";
import { AddNewItemWithAmountComponent } from "../AddNewItemWithAmountComponent";

interface ItemGroupCardProps {
  itemGroup: ItemGroup;
  onClick: (itemGroup: ItemGroup) => void;
}

const ItemGroupCard: React.FC<ItemGroupCardProps> = ({
  itemGroup,
  onClick,
}) => {
  const { RecommendedItem, name } = itemGroup;
  const imageUrl = RecommendedItem.image || "https://via.placeholder.com/300";
  const price =
    RecommendedItem.discountPrice || RecommendedItem.normalPrice || 0;

  const capitalizeWords = (str: string) => {
    return str.replace(/(^|\s)(\p{L})/gu, (match, p1, p2) => {
      return p1 + p2.toLocaleUpperCase("da-DK");
    });
  };

  const handleClick = () => {
    onClick(itemGroup);
  };

  function addItemClicked() {
    // do nothing other than add
    console.log("addItemClicked called");
  }

  return (
    <div className="bg-white rounded-lg overflow-hidden shadow-md flex flex-col h-full transform transition duration-300 hover:scale-105 hover:shadow-lg cursor-pointer">
      <div className="" onClick={handleClick}>
        <div className="flex-1 relative">
          <img
            src={imageUrl}
            alt={name}
            className="w-full h-48 object-contain"
          />
        </div>
        <div className="p-2 flex justify-between items-center">
          <span className="font-semibold text-sm">{capitalizeWords(name)}</span>
          <span className="font-semibold text-sm text-gray">
            {(price / 100).toFixed(2)} DKK
          </span>
        </div>
      </div>
      <div className="w-full p-2 mt-auto">
        <AddNewItemWithAmountComponent
          itemGroup={itemGroup}
          index={itemGroup.id}
          addItemClicked={() => addItemClicked()}
        />
      </div>
    </div>
  );
};

export default ItemGroupCard;
