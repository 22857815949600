export const handleApiError = (error: any): string => {
  if (error.response) {
    // Server responded with error
    switch (error.response.status) {
      case 400:
        return "Ugyldig anmodning. Kontroller venligst dine data.";
      case 401:
        return "Ikke autoriseret. Log venligst ind igen.";
      case 403:
        return "Ingen adgang til denne ressource.";
      case 404:
        return "Den anmodede ressource blev ikke fundet.";
      case 500:
        return "Der opstod en serverfejl. Prøv igen senere.";
      default:
        return `Der opstod en fejl: ${
          error.response.data.message || "Ukendt fejl"
        }`;
    }
  } else if (error.request) {
    // Request made but no response
    return "Kunne ikke få forbindelse til serveren. Tjek din internetforbindelse.";
  } else {
    return "Der opstod en uventet fejl. Prøv igen senere.";
  }
};
