import React from "react";
import { ItemGroup } from "../../model/ItemGroup";
import ItemGroupCard from "./ItemGroupCardComponent";

interface ItemGroupSectionProps {
  section: string;
  itemGroups: ItemGroup[];
  onCardClick: (itemGroup: ItemGroup) => void;
}

const ItemGroupSection: React.FC<ItemGroupSectionProps> = ({
  section,
  itemGroups,
  onCardClick,
}) => {
  // Mapping of section keys to display names
  const sectionDisplayNames: { [key: string]: string } = {
    "frugt-og-groent": "Frugt og Grønt",
    "koed-og-fisk": "Kød og Fisk",
    "mejeri-og-koel": "Mejeri og køl",
    "drikkevarer": "Drikkevarer",
    "broed-og-kager": "Brød og Kager",
    "kolonial": "Kolonial",
    "frost": "Frost",
    "slik-og-snacks": "Slik og Snacks",
  };

  // Get the display name for the section
  const displayName = sectionDisplayNames[section] || section;

  return (
    <div className="bg-white rounded-lg shadow-sm p-6 mb-6">
      <h2 className="mb-4 text-2xl font-semibold">{displayName}</h2>
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4">
        {itemGroups.map((itemGroup) => (
          <ItemGroupCard
            key={itemGroup.id}
            itemGroup={itemGroup}
            onClick={onCardClick}
          />
        ))}
      </div>
    </div>
  );
};

export default ItemGroupSection;
