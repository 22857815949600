import Slider from "@mui/material/Slider";
import { useEffect, useState } from "react";
import { AddRecipeButtonComponent } from "../../components/recipes/AddRecipeButtonComponent";
import { RecipeCardComponent } from "../../components/recipes/RecipeCardComponent";
import { RecipeCategoryButtonComponent } from "../../components/recipes/RecipeCategoryButtonComponent";
import SearchBar from "../../components/SearchBarComponent";
import { Recipe } from "../../model/Recipe";
import { getRecipes } from "../../services/apiService";
import { meatCategories } from "../../constant/recipeCategories";

export function RecipePage() {
  const [recipes, setRecipes] = useState<Recipe[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [timeRange, setTimeRange] = useState<number[]>([0, 60]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filteredRecipes, setFilteredRecipes] = useState<Recipe[]>([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [categories] = useState(meatCategories);

  // Fetch recipes
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const fetchedRecipes = await getRecipes();
        setRecipes(fetchedRecipes);
      } catch (error) {
        setError("Failed to load recipes");
        console.error("Error fetching recipes:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  // Filter recipes
  useEffect(() => {
    const updateFilteredRecipes = () => {
      const filtered = recipes.filter((recipe) => {
        if (!recipe) return false;

        const matchesCategories =
          selectedCategories.length === 0 ||
          selectedCategories.some((category) =>
            recipe.categories?.includes(category)
          );

        let matchesTimeRange = recipe.time
          ? recipe.time >= timeRange[0]
          : false;

        if (timeRange[1] === 60) {
          matchesTimeRange =
            matchesTimeRange || (recipe.time ? recipe.time > 60 : false);
        } else {
          matchesTimeRange =
            matchesTimeRange &&
            (recipe.time ? recipe.time <= timeRange[1] : false);
        }

        const lowerCaseSearchTerm = searchTerm.toLowerCase();

        const matchesName = recipe.recipeName
          ? recipe.recipeName.toLowerCase().includes(lowerCaseSearchTerm)
          : false;

        return matchesCategories && matchesTimeRange && matchesName;
      });
      setFilteredRecipes(filtered);
    };
    updateFilteredRecipes();
  }, [recipes, selectedCategories, timeRange, searchTerm]);

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    setTimeRange(newValue as number[]);
  };

  const handleCategoryToggle = (category) => {
    setSelectedCategories((prevSelected) =>
      prevSelected.includes(category)
        ? prevSelected.filter((c) => c !== category)
        : [...prevSelected, category]
    );
  };

  const TimeValueFormat = (value: number) => {
    return value >= 60 ? "60+" : value;
  };

  return (
    <div id="recipe_page" className="flex flex-col p-10 w-full">
      <div id="recipe_top" className="flex flex-row w-full">
        <div id="recipe_header" className="flex space-x-5 ">
          <button className="relative h-12 text-lg shadow-md shadow-darkgreyBackground text-darkText font-bold bg-action px-4 py-2 rounded-md">
            ALLE OPSKRIFTER
          </button>
        </div>
        <div id="recipe_searchfield" className="flex ml-auto">
          <SearchBar
            onChange={setSearchTerm}
            placeholder="Søg efter opskrifter..."
          ></SearchBar>
        </div>
      </div>

      <div id="recipe_filter_categories" className="flex pt-5 pb-5 space-x-3">
        {categories.map((category) => (
          <RecipeCategoryButtonComponent
            key={category}
            category={category}
            onClick={handleCategoryToggle}
            isSelected={selectedCategories.includes(category)}
          >
            {category}
          </RecipeCategoryButtonComponent>
        ))}
      </div>

      <div className="ms-2 flex flex-row space-x-3 justify-center items-center w-4/12">
        <Slider
          getAriaLabel={() => "time"}
          value={timeRange}
          onChange={handleSliderChange}
          valueLabelDisplay="off"
          valueLabelFormat={TimeValueFormat}
          min={0}
          max={60}
          step={15}
        />
        <img
          src="./icon/recipes_page/time.png"
          alt="time_filter"
          className="size-6"
        ></img>
      </div>

      <div className="flex justify-between w-4/12">
        <span>0</span>
        <span>15</span>
        <span>30</span>
        <span>45</span>
        <span>60+</span>
      </div>

      <div>
        {isLoading ? (
          <div className="mt-4">Loading recipes...</div>
        ) : error ? (
          <div className="mt-4 text-red-500">
            Error loading recipes: {error}
          </div>
        ) : (
          <RecipeCardComponent recipes={filteredRecipes} />
        )}
      </div>

      <AddRecipeButtonComponent />
    </div>
  );
}
