import { ItemGroup } from "../model/ItemGroup";
import { Recipe } from "../model/Recipe";
import { ItemGroupWithDesiredWeight } from "../model/ItemGroupWithDesiredWeight";
import axiosInstance from "./axiosInstance";
import { toast } from "react-toastify";
import { handleApiError } from "../utils/handleApiError";

export async function getItemGroups(name: string): Promise<ItemGroup[]> {
  try {
    const response = await axiosInstance.get<ItemGroup[]>(
      `getitemgroups/${name}`
    );
    return response.data;
  } catch (error) {
    const errorMessage = handleApiError(error);
    toast.error(errorMessage);
    return [];
  }
}

export async function getItemGroup(name: string): Promise<ItemGroup | null> {
  try {
    const response = await axiosInstance.get<ItemGroup>(`getitemgroup/${name}`);
    return response.data;
  } catch (error) {
    const errorMessage = handleApiError(error);
    toast.error(errorMessage);
    return null;
  }
}

export async function getRecipes(): Promise<Recipe[]> {
  try {
    const response = await axiosInstance.get<Recipe[]>("getRecipes");
    return response.data;
  } catch (error) {
    const errorMessage = handleApiError(error);
    toast.error(errorMessage);
    console.error("Failed to fetch recipes:", error);
    return [];
  }
}

export async function getRecipe(recipeId: number): Promise<Recipe | null> {
  try {
    const response = await axiosInstance.get<Recipe>(`getRecipe/${recipeId}`);
    return response.data;
  } catch (error) {
    const errorMessage = handleApiError(error);
    toast.error(errorMessage);
    return null;
  }
}

export async function createRecipe(
  recipe_data: Recipe
): Promise<Recipe | null> {
  try {
    const response = await axiosInstance.post<{
      message: string;
      receivedData: Recipe;
    }>("createRecipe", recipe_data);
    toast.success("Opskrift oprettet succesfuldt");
    return response.data.receivedData;
  } catch (error) {
    const errorMessage = handleApiError(error);
    toast.error(errorMessage);
    return null;
  }
}

export async function createItemGroupWithDesiredWeight(
  desiredWeight: number,
  desiredUnit: string,
  itemGroup: ItemGroup
): Promise<ItemGroupWithDesiredWeight | null> {
  try {
    const body = { desiredWeight, desiredUnit, itemGroup };
    const response = await axiosInstance.post(
      "createItemGroupWithDesiredWeight",
      body
    );
    return response.data;
  } catch (error) {
    const errorMessage = handleApiError(error);
    toast.error(errorMessage);
    return null;
  }
}
