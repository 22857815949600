import axios from "axios";
import { signOut } from "firebase/auth";
import { auth } from "../utils/firebaseSetup";
import { toast } from "react-toastify";

const BASE_URL = process.env.REACT_APP_BACKEND_URL;

const axiosInstance = axios.create({
  baseURL: BASE_URL,
});

axiosInstance.interceptors.request.use(
  async (config) => {
    const user = auth.currentUser;

    if (user && config.headers) {
      try {
        const token = await user.getIdToken();
        config.headers["Authorization"] = `Bearer ${token}`;
      } catch (error) {
        console.error("Error retrieving ID token:", error);
      }
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (!error.response) {
      // Network error
      toast.error(
        "Kunne ikke få forbindelse til serveren. Tjek din internetforbindelse."
      );
      return Promise.reject(error);
    }

    if (error.response.status === 401 && !error.config._retry) {
      error.config._retry = true;
      try {
        const user = auth.currentUser;
        if (user) {
          const token = await user.getIdToken(true);
          error.config.headers["Authorization"] = `Bearer ${token}`;
          return axiosInstance(error.config);
        }
      } catch (err) {
        console.error("Token refresh failed:", err);
        await signOut(auth);
        toast.error("Din session er udløbet. Log venligst ind igen.");
      }
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
