import { useEffect, useRef, useState } from "react";
import { AddNewItemWithAmountComponent } from "../../components/AddNewItemWithAmountComponent";
import SearchBar from "../../components/SearchBarComponent";
import { ShoppingListItemComponent } from "../../components/shoppingList/ShoppingListItemComponent";
import { StorePriceComponent } from "../../components/StorePriceComponent";
import { AvailableStoresList } from "../../constant/availableStores";
import { useShoppingList } from "../../hooks/useShoppingList";
import { ItemGroup } from "../../model/ItemGroup";
import { getItemGroups } from "../../services/apiService";

export function ShoppingListPage() {
  const { shoppingList, isSomethingMarked, clearList, totalPrice } =
    useShoppingList();

  const [markedStore, setMarkedStore] = useState("Rema");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [itemGroups, setItemGroups] = useState<ItemGroup[]>([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const searchBarRef = useRef<HTMLDivElement>(null);
  const [chosenStoreIndex, setChosenStoreIndex] = useState(0);

  useEffect(() => {
    const index = AvailableStoresList.findIndex(
      (store) => store === markedStore
    );
    setChosenStoreIndex(index);
  }, [markedStore]);

  function addItemClicked() {
    // setIsDropdownOpen(false);
  }

  useEffect(() => {
    console.log("useEffect triggered with searchTerm:", searchTerm); // Check when effect runs

    const fetchItemGroups = async () => {
      if (searchTerm.trim() === "") {
        setItemGroups([]);
        setIsDropdownOpen(false);
        return;
      }

      if (searchTerm.length > 50) {
        setIsDropdownOpen(false);
        return;
      }

      try {
        const data = await getItemGroups(searchTerm);
        setItemGroups(data);
        setIsDropdownOpen(true);
      } catch (err: any) {
        console.error("Failed to fetch item groups.");
        setIsDropdownOpen(false);
      }
    };

    const debounceTimeout = setTimeout(() => {
      fetchItemGroups();
    }, 500);

    return () => {
      clearTimeout(debounceTimeout);
    };
  }, [searchTerm]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        searchBarRef.current &&
        !searchBarRef.current.contains(event.target as Node)
      ) {
        setIsDropdownOpen(false); // Close dropdown if clicked outside
      }
    };

    // Attach the event listener to detect outside clicks
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="flex flex-col pl-16 bg-lightBackground text-ellipsis whitespace-nowrap w-full h-full justify-between px-10 overflow-x-hidden">
      <div className="flex flex-row items-center justify-start w-11/12">
        <h1 className="text-4xl text-darkText mb-10 mt-7 mr-10">
          Indkøbsliste
        </h1>

        <div className="relative flex-grow max-w-2xl" ref={searchBarRef}>
          <SearchBar
            onChange={(value) => setSearchTerm(value)}
            placeholder="Søg efter varer..."
          />
          {isDropdownOpen && itemGroups.length > 0 && (
            <ul className="absolute  left-0 top-full mt-2 w-full bg-white border border-gray-300 rounded-lg max-h-80 overflow-y-auto shadow-lg z-10">
              {itemGroups.map((group, index) => (
                <li
                  key={index}
                  className="p-2 h-20 hover:bg-gray-200 cursor-pointer flex flex-row justify-between items-center"
                >
                  <img
                    src={group.RecommendedItem.image}
                    className="w-1/12 m-1 h-full object-cover"
                    alt={group.RecommendedItem.name}
                  />
                  <p className="w-20 truncate flex flex-grow text-darkText">
                    {group.name}
                  </p>

                  <div className="m-0">
                    <AddNewItemWithAmountComponent
                      itemGroup={group}
                      index={index} // Ensure you pass a valid index here
                      addItemClicked={() => addItemClicked()}
                    />
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
      <div
        id="shopping_list"
        className="flex flex-col relative w-full border-t border-two text-darkText overflow-y-auto"
        style={{ height: "calc(100vh - 400px)" }}
      >
        <div className="overflow-y-auto flex-grow flex flex-col w-full">
          {shoppingList.length === 0 && (
            <p>Der er ikke tilføjet nogle vare endnu</p>
          )}

          {shoppingList.map((item) => (
            <ShoppingListItemComponent
              key={item.itemGroup.id}
              shoppingListItem={item}
              choosenStore={markedStore}
            />
          ))}
        </div>
        <div className="w-full h-20 flex justify-end">
          {isSomethingMarked() === true && (
            <button
              className="bg-cancel text-darkText w-14 h-14 rounded-full flex justify-center items-center p-2 m-2"
              onClick={() => {
                clearList();
              }}
            >
              <img src="./icon/delete.png" alt="Delete" />
            </button>
          )}
        </div>
      </div>
      <div className="flex flex-row my-5 justify-between sticky bottom-0 p-4 w-full border-t-2 border-lightgreyBackground bg-lightBackground mt-auto">
        <div className="flex flex-row space-x-3">
          {AvailableStoresList.map((store, index) => (
            <StorePriceComponent
              key={store}
              store={store}
              clickable={true}
              onSelectStore={setMarkedStore}
              isSelected={markedStore === store}
              totalPrice={totalPrice[index]}
            />
          ))}
        </div>

        <div className="flex bg-secondary items-center space-x-2 p-2 rounded-full w-fit h-14">
          <p className="text-3xl p-3 font-bold text-darkText">
            {((totalPrice[chosenStoreIndex] || 0) / 100).toFixed(2)} kr
          </p>
        </div>
      </div>
      <p className="font-bold">
        *Butikker der er markeret med gult betyder, at nogle varer ikke er
        tilgængelige i butikken
      </p>
    </div>
  );
}

export default ShoppingListPage;
