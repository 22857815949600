import { confirmAlert } from "react-confirm-alert";

export function ConfirmComponent(
  title: string,
  message: string,
  acceptButtonText: string,
  denyButtonText: string,
  AcceptClick: () => void,
  DenyClick: () => void
) {
  confirmAlert({
    customUI: ({ onClose }) => (
      <div className="react-confirm-alert-body my-custom-modal">
        <h2>{title}</h2>
        <p>{message}</p>
        <div className="react-confirm-alert-button-group">
          <button
            onClick={async () => {
              onClose();
              AcceptClick();
            }}
          >
            {acceptButtonText}
          </button>
          <button
            onClick={async () => {
              onClose();
              DenyClick();
            }}
          >
            {denyButtonText}
          </button>
        </div>
      </div>
    ),
  });
  return;
}
