import { Recipe } from "../model/Recipe";

export function findBestStoreForRecipe(recipe: Recipe) {
  const storeWithMissingIngredientCount = recipe.pricePerStore.map((store) => {
    const missingIngredientCount = recipe.ingredients.reduce(
      (count, ingredient) => {
        const matchingStore = ingredient.pricePerStore?.find(
          (s) => s.storeName === store.storeName
        );
        return matchingStore &&
          (matchingStore.price === null || matchingStore.price === undefined)
          ? count + 1
          : count;
      },
      0
    );
    return { ...store, missingIngredientCount };
  });

  const sortedStores = storeWithMissingIngredientCount.sort((a, b) => {
    if (a.missingIngredientCount !== b.missingIngredientCount) {
      return a.missingIngredientCount - b.missingIngredientCount;
    }
    return a.price - b.price;
  });

  const cheapestStoreWithMostIngredients = sortedStores[0];
  const hasMissingIngredients =
    cheapestStoreWithMostIngredients?.missingIngredientCount > 0;

  return { cheapestStoreWithMostIngredients, hasMissingIngredients };
}
